import './index.sass'
import "../../main.css"

import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import Preloader from "../../components/preloader";
import Notification from "../../components/notification";
import {NotificationManager} from "react-notifications";
import LoginHandler from "../../functions/login";

 function AvatarPage() {
    const {AccountStore} = StoreContext();
    //var blc=AccountStore.getBlcData();
    const [Avatars, setActiveAvatars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [cost, setCost] = useState("0.0000 DOVX");
    const loginHandler = new LoginHandler();

    
    useEffect(() => {
        if (AccountStore.accountAddress) {
        }
    }, [AccountStore.accountAddress])

    async function choose(id) {
            await sendTransaction([{
                account: loginHandler.Gamecontract,
                name: 'setavatar',
                authorization: [{
                    actor: AccountStore.accountAddress,
                    permission: AccountStore.getUserData()[0] === "anchor" ? AccountStore.getUserData()[3] : "active",
                }],
                data: {
                    player: AccountStore.accountAddress,
                    avatar_id: id
                },
            }],AccountStore.getUserData()[0], "Transaction successfull")
            setTimeout(() => {
                loginHandler.getUserBalancetkn();
                loginHandler.getUserBalancewax();
            }, 1000)
        }

    async function sendTransaction(actions, wallet, successMessage) {
        setLoading(true);
        try {
            if (wallet === "wcw") {
                const wax = new waxjs.WaxJS("https://dovx-wax-rpc.global.binfra.one", AccountStore.accountAddress, AccountStore.getUserData()[2], false)
                const res = await wax.api.transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res;
            } else if (wallet === "anchor") {
                const res = await AccountStore.getUserData()[2].transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res.processed.id
            }
        } catch (e) {
            setLoading(false);
            if (e.message === "assertion failure with message: Nothing to claim") {
                NotificationManager.warning("Nothing to claim.", "Warning")
            } else {
                NotificationManager.error(e.message, "An error has occurred.");
            }
        }
    }
    const handleAvType = (asset) => {
        setCount(asset.id);
        setCost(asset.cost);
      };

    return (
            <div className="parent verticalCenter">
                  <div className="avatar">
                  <h1>SELECT YOUR AVATAR ICON</h1>
                  <h2>Cost: <span>{AccountStore.avatarData.length>0?AccountStore.avatarData[0].cost:cost}</span></h2>
            {AccountStore.avatarData.map((asset,index) => {
                return (
                   <label key={index}>
                   {AccountStore.getAvatarIndex().id===asset.id && count===0?<input type="radio" name="avatarIcon" value={asset.id} checked />
                    :<input type="radio" name="avatarIcon" value={asset.id} />} 
                          <img 
                            src={`https://dov-gw.mypinata.cloud/ipfs/${asset.ipfs}`} 
                            alt=""
                                      onClick={()=>{
                                        handleAvType(asset);
                                    } }
                          />
             {asset.id===4||asset.id===7||asset.id===11?<div className="clearfix"/>:<></>}
             </label>
             )})}
                        <div className="btnBlue"><button
                         onClick={()=>{
                            choose(count);
                        } }
                        >Save</button></div>
             </div>
                </div>
    )
}

export default observer(AvatarPage);
