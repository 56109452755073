import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import eosjsName from "eosjs-account-name";
import Pagination from "../../components/pagination";
import Notification from "../../components/notification";
import { NotificationManager } from "react-notifications";
import Preloader from "../../components/preloader";
import { Int64 } from "anchor-link";
import MyTimer from "../../components/timer";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";

function PageD() {
  const { AccountStore } = StoreContext();
  const [loading, setLoading] = useState(false);
  const [Zconfig, SetZConfig] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const contract = "dovsimulator";
  const loginHandler = new LoginHandler();
  const wax = loginHandler.wax;

  function get_rtime(endtime) {
    var current = new Date().getTime() / 1000;
    var tr = endtime - current;
    if (tr < 0) return 0;
    else {
      var time = new Date().getTime() + tr * 1000;
      return time;
    }
  }


  function getRewardValue(asset) {
      var selectedZone= asset.zone;
      if (!selectedZone.resolutions) return 0;
      let vv = asset;
      for (let i = 0; i < selectedZone.resolutions.length; i++) {
        if (selectedZone.resolutions[i].key === vv.win) {
          return (selectedZone.resolutions[i].value/10000) *(1.00+ parseFloat(vv.bonusd/1000000));
        }
      }
      return (selectedZone.resolutions[0].value/10000) *(1.00+ parseFloat(vv.bonusd/1000000));
  }

  useEffect(() => {
    if (AccountStore.accountAddress) {
      loginHandler.getUserBalancetkn();
      loginHandler.getUserBalancewax();
      loginHandler.getAssets();
    }
  }, [AccountStore.accountAddress]);

  async function sendTransaction(actions, wallet, successMessage) {
    setLoading(true);
    try {
      if (wallet === "wcw") {
        const wax = loginHandler.wax;
        const res = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 30,
          }
        );
        setLoading(false);
        NotificationManager.success(successMessage);
        return res;
      } else if (wallet === "anchor") {
        const res = await AccountStore.getUserData()[2].transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 30,
          }
        );
        setLoading(false);
        NotificationManager.success(successMessage);
        return res.processed.id;
      }
    } catch (e) {
      setLoading(false);
      if (e.message === "assertion failure with message: Nothing to claim") {
        NotificationManager.warning("Nothing to claim.", "Warning");
      } else {
        NotificationManager.error(e.message, "An error has occurred.");
      }
    }
  }

  async function startFight(division_id, zoneID) {
    var returnData = {
      player: AccountStore.accountAddress,
      division_id: division_id,
      zoneID: zoneID,
    };
    console.log(returnData);
    await sendTransaction(
      [
        {
          account: "dovsimulator",
          name: "startfight",
          authorization: [
            {
              actor: AccountStore.accountAddress,
              permission:
                AccountStore.getUserData()[0] === "anchor"
                  ? AccountStore.getUserData()[3]
                  : "active",
            },
          ],
          data: {
            player: AccountStore.accountAddress,
            division_id: division_id,
            zoneID: zoneID,
          },
        },
      ],
      AccountStore.getUserData()[0],
      "Transaction successfull"
    );
    setTimeout(() => {
      loginHandler.getUserBalancetkn();
      loginHandler.getUserBalancewax();
      loginHandler.getAssets();
    }, 1000);
  }

  async function claimFight(division_id, zoneID) {
    var returnData = {
      player: AccountStore.accountAddress,
      division_id: division_id,
      zoneID: zoneID,
    };
    console.log(returnData);
    await sendTransaction(
      [
        {
          account: "dovsimulator",
          name: "claimfight",
          authorization: [
            {
              actor: AccountStore.accountAddress,
              permission:
                AccountStore.getUserData()[0] === "anchor"
                  ? AccountStore.getUserData()[3]
                  : "active",
            },
          ],
          data: {
            player: AccountStore.accountAddress,
            division_id: division_id,
          },
        },
      ],
      AccountStore.getUserData()[0],
      "Transaction successfull"
    );
    setTimeout(() => {
      loginHandler.getUserBalancetkn();
      loginHandler.getUserBalancewax();
      loginHandler.getAssets();
    }, 1000);
  }
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <div className="parent verticalCenter">
          {AccountStore.divisions.map((asset, index) => {
            return (
              <>
                <div className="divFightZoneActive" key={index}>
                  <div className="divFightTitle">
                    <a href="./create" rel="noreferrer">
                      <span>DIVISION {index + 1}</span>
                    </a>
                  </div>{" "}
                  <div className="divFightInfo">
                    <div className="costZoneMin">
                    <span>
                        {(
                          (asset.zone.costs.filter((a) => a.key === "DOVH").length>0?asset.zone.costs.filter((a) => a.key === "DOVH")[0]
                            .value:0) / 10000
                        ).toFixed(4)}{" "}
                      </span>
                      <span>
                        {(
                          (asset.zone.costs.filter((a) => a.key === "DOVX").length>0?asset.zone.costs.filter((a) => a.key === "DOVX")[0]
                            .value:0) / 10000
                        ).toFixed(4)}{" "}
                      </span>
                    </div>
                    <div className="winsZoneMin">
                      <span>{asset.zone.nbwins}</span>
                    </div>
                    <div className="earningsZoneMin">
                      <span>
                        {getRewardValue(asset).toFixed(3)}
                      </span>
                    </div>

                  </div>
                  <div className="divFightInfo2">
                    <div className="divZoneMin mgt10">
                      <div className="divLeftDPMin">
                        <span>Division Power</span>
                      </div>
                      <div className="divRightDPMin orangeColor">
                        <span>
                          {(
                            parseFloat(asset.Total_Power)
                          ).toFixed(1)}
                        </span>
                      </div>
                      {/* <div className="divLeftDPMin"><span>Division Bonus</span></div>
                                            <div className="divRightDPMin orangeColor"><span>{(parseFloat(asset.bonusw)/10000).toFixed(4)} %</span></div> */}
                    </div>
                    <div className="divZoneMin">
                      <div className="divAuthMin">
                        <button
                          className={asset.win !== "X" ? "greenBack" : ""}
                        >
                          Authorized to fight
                        </button>
                      </div>
                      <div className="divAuthMin">
                        <button
                          className={asset.win === "X" ? "orangeColor" : ""}
                        >
                          Unauthorized to fight
                        </button>
                      </div>
                    </div>
                    <div className="divZoneMin">
                      <div className="divVDDMin">
                        <button
                          className={asset.win === "win" ? "orangeColor" : ""}
                        >
                          Victory
                        </button>
                      </div>
                      <div className="divVDDMin">
                        <button
                          className={asset.win === "draw" ? "orangeColor" : ""}
                        >
                          Draw
                        </button>
                      </div>
                      <div className="divVDDMin">
                        <button
                          className={asset.win === "loss" ? "orangeColor" : ""}
                        >
                          Defeat
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="divFightVictory">
                    <span className="orangeBlock mgt15">
                      {asset.zone
                        ? AccountStore.ZoneWins.filter(
                            (a) => a.zone === asset.zone.id
                          )[0]
                          ? AccountStore.ZoneWins.filter(
                              (a) => a.zone === asset.zone.id
                            )[0].wins
                          : 0 || 0
                        : 0}{" "}
                      Victories
                    </span>
                    <span className="whiteBlock">in zone</span>
                    <span className="orangeBlock">{asset.zone.name}</span>
                  </div>
                  <div className="divFightButton">
                    <div className="btnFight">
                      {asset.end_time === 0 ? (
                        <button
                          onClick={() => startFight(asset.divID, asset.zone.id)}
                        >
                          Fight
                        </button>
                      ) : asset.end_time !== -1 ? (
                        <button>
                          <p className="timerz">
                            <MyTimer
                              expiryTimestamp={loginHandler.get_rtime(
                                parseInt(asset.end_time)
                              )}
                              divID={asset.divID}
                            />
                          </p>
                          <></>
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() =>
                              claimFight(asset.divID, asset.zone.id)
                            }
                          >
                            Claim
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default observer(PageD);
