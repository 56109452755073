import './index.sass'
import '../../main.css'
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import eosjsName from "eosjs-account-name";
import Pagination from "../../components/pagination";
import Notification from "../../components/notification";
import { NotificationManager } from "react-notifications";
import Preloader from "../../components/preloader";
import { Int64 } from 'anchor-link';
import LoginHandler from "../../functions/login";
import { useTimer } from 'react-timer-hook';
import MyTimer from "../../components/timer";

function StakingPage() {
    const { AccountStore } = StoreContext();
    const [unstakedAssets, setUnstakedAssets] = useState([]);
    const [stakedAssets, setStakedAssets] = useState([]);
    const [stakedTools, setStakedTools] = useState([{ type: "slot", varz: "clock" }, { type: "slot", varz: "booster" }, { type: "slot", varz: "effort" }]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const contract = "dovutilstake";
    const assetsPerPage = 4; //how many assets are shown on screen
    const loginHandler = new LoginHandler();
    const [popped, popUp] = useState(false);
    const [useDOV, SetDOVX] = useState(true);
    const [fusionType, SetfusionType] = useState("All");
    const [utilityType, SetutilityType] = useState("All");

    const wax = loginHandler.wax;

    const Dropdown = ({ label, value, options, onChange }) => {
        return (
          <label>
            {label}
            <select value={value} onChange={onChange} className='optionSelect'>
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </label>
        );
      };

    async function sendTransaction(actions, wallet, successMessage) {
        setLoading(true);
        try {
            if (wallet === "wcw") {
                const wax = loginHandler.wax;
                const res = await wax.api.transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res;
            } else if (wallet === "anchor") {
                const res = await AccountStore.getUserData()[2].transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res.processed.id
            }
        } catch (e) {
            setLoading(false);
            if (e.message === "assertion failure with message: Nothing to claim") {
                NotificationManager.warning("Nothing to claim.", "Warning")
            } else {
                NotificationManager.error(e.message, "An error has occurred.");
            }
        }
    }

    async function getAssets() {
       loginHandler.getConfig();
        setLoading(true);
        try {
            const assets = [];
            const response = await fetch(loginHandler.aa+`/atomicassets/v1/assets?collection_name=${loginHandler.collection}&owner=${AccountStore.accountAddress}&page=1&limit=1000&order=desc&sort=asset_id`, {
                headers: {
                    "Content-Type": "text/plain"
                },
                method: "GET",
            });
            const body = await response.json();
            console.log(body);
            for (let i = 0; i < body.data.length; i++) {
                let data = body.data[i];
                assets.push({
                    asset_id: data.asset_id,
                    img: data.data.img,
                    name: data.name,
                    template: data.template,
                    schema: data.schema.schema_name,
                    fusion:data.data.fusion,
                    utility:data.data.utility
                })
            }
            console.log(assets);
            await getTools(assets);
        } catch (e) {
            setLoading(false);
            NotificationManager.error(e.message, "An error has occurred.");
        }
    }

    async function getStakingRates(assets) {
        const body = await wax.rpc.get_table_rows({
            json: true,
            code: contract,
            scope: contract,
            table: "bdata",
            limit: 1000,
        });
        const data = body.rows;
        console.log(data);
        const rates = [];
        data.forEach(x => {
            rates.push({
                template_id: x.template_id,
                token_in: x.token_in,
                type: x.type,
                token_out: x.token_out,
                rarity: x.rarity,
                delay: x.delay
            })
        });
        const buildings = [];
        for (let i = 0; i < assets.length; i++) {
            rates.forEach(y => {
                if (assets[i].template.template_id == y.template_id) {
                    assets[i].token_in = y.token_in;
                    assets[i].type = y.type;
                    assets[i].token_out = y.token_out;
                    assets[i].rate = y.token_out;
                    assets[i].rarity = y.rarity;
                    assets[i].delay = (stakedTools[0].type == "clock") ? y.delay * (stakedTools[0].rarity/6) : y.delay;
                    console.log(stakedTools[0].type);
                    buildings.push(assets[i]);
                }
            });

        }
        filterAssets(buildings);
    }

    async function filterAssets(assets) {
        const filteredAssets = [];

        const body = await wax.rpc.get_table_rows({
            json: true,
            code: contract,
            scope: contract,
            table: "buildings",
            key_type: "i64",
            index_position: 2,
            lower_bound: eosjsName.nameToUint64(AccountStore.accountAddress),
            upper_bound: eosjsName.nameToUint64(AccountStore.accountAddress),
            limit: 1000,
        });
        const data = body.rows;

        if (data.length !== 0) {
            assets.forEach(asset => {
                data.forEach(dataItem => {
                    if (dataItem.asset_id === asset.asset_id && dataItem.owner === AccountStore.accountAddress) {
                        asset.last_claim = dataItem.last_claim;
                        var tr = parseInt(get_rtime(parseInt(asset.last_claim) + parseInt(asset.delay)));
                        asset.tr = parseInt(tr);
                        console.log(tr);
                        var current = new Date().getTime() / 1000;
                        var endt = parseInt(asset.last_claim) + parseInt(asset.delay);
                        var rt = endt - current;
                        if (rt <= 0) asset.claim = true;
                        else asset.claim = false;
                        filteredAssets.push(asset);
                    }
                })
            })
        }
        console.log(filteredAssets);
        setStakedAssets(filteredAssets);
        const unstakedAssets = assets.filter(item => {
            if (!filteredAssets.includes(item) && item.type.includes("building")) {
                return item;
            }
        })
        setUnstakedAssets(unstakedAssets);
        setLoading(false);
        popUp(false);
    }


    async function getTools(assets) {
        const finalAssets = stakedTools;
        const body = await wax.rpc.get_table_rows({
            json: true,
            code: "dovutilstake",
            scope: "dovutilstake",
            table: "mships",
            key_type: "i64",
            index_position: 2,
            lower_bound: eosjsName.nameToUint64(AccountStore.accountAddress),
            upper_bound: eosjsName.nameToUint64(AccountStore.accountAddress),
            limit: 4,
        });
        const data = body.rows;
        if (data.length !== 0) {
            data.forEach(dataItem => {
                if (dataItem.owner === AccountStore.accountAddress) {
                    if (dataItem.type == "clock")
                        finalAssets[0] = dataItem;
                    if (dataItem.type == "booster")
                        finalAssets[1] = dataItem;
                    if (dataItem.type == "effort")
                        finalAssets[2] = dataItem;
                }
            })
        }
        console.log(finalAssets);
       SetDOVX(AccountStore.getConfig().use_dov===1);
       console.log(AccountStore.getConfig());
        setStakedTools(finalAssets);
        await getStakingRates(assets);
    }



        function get_rtime(endtime) {
            var current = new Date().getTime() / 1000;
            var tr = endtime - current;
            if (tr < 0) tr = 0;
            var time = new Date().getTime() + tr * 1000;
            return time;
        }

        function renderAssets() {
            const indexOfLastAsset = currentPage * assetsPerPage;
            const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
            const currentAssets = stakedAssets;
            var slotno = 4 - stakedAssets.length;
            const slots = [];
            for (let i = 0; i < slotno; i++) { slots.push("") };
            return (
                <>
                    {currentAssets.map((asset, index) => {
                        return (
                            <div className={!asset.claim ? "cards_buildings startCounter" : "cards_buildings endCounter"} key={`staked-asset-${index}`}>
                                <img src={`https://dov-gw.mypinata.cloud/ipfs/${asset.img}`} alt="" classNsame="staking-asset-img selected" />
                                <p className="floatText">/ {asset.delay / 3600} hours</p>
                                <div className="textCounter">
                                    <h2>{asset.asset_id}</h2>
                                    {!asset.claim ? <h3>Will end in</h3> : <h3>Is Finished</h3>}
                                    <p className="timerz"><MyTimer expiryTimestamp={asset.tr} /></p>
                                    <button className="disassemblyBtn" onClick={() => unStakeAssets([asset.asset_id])} > </button>
                                </div>
                                {!asset.claim ? <div className="cards_button dovxZone">
                                    {asset.token_in.map((cost, index) => {
                                    var booster=parseFloat(stakedTools[2].type == "effort"?stakedTools[2].rarity:0).toFixed(4);
                                    var symbol = cost.split(" ")[1];
                                    var cost = parseFloat(cost.split(" ")[0] * (asset.delay / 3600)).toFixed(4);
                                        return (
                                            <div className="dovxText" key={`cons-${index}`}>
                                                <h3>{symbol}</h3>
                                                <p>{cost} </p>
                                                {booster!=0 && symbol=="DOVX"?
                                                <p className="miniText blueColor">{parseFloat((cost*(1-(booster/100)))).toFixed(4)} {symbol=="DOVX"?<span className="whiteColor">(-{parseFloat(booster).toFixed(0)}%)</span>:<></>} </p>:<></>}
                                            </div>
                                        )
                                    })}
                                        {asset.token_out.map((cost, index) => {
                                            var booster=parseFloat(stakedTools[1].type == "booster"?stakedTools[1].rarity:0).toFixed(4);
                                            var symbol = cost.split(" ")[1];
                                            var cost = parseFloat(cost.split(" ")[0] * (asset.delay / 3600)).toFixed(4);
                                            return (
                                                <div className="dovxText" key={`cons-${index}`}>
                                                    <h3>{symbol}</h3>
                                                    <h4>{cost} </h4>
                                                    {booster!=0?
                                                    <p className="miniText blueColor">{parseFloat(cost*((booster/100)+1)).toFixed(4)} {<span className="whiteColor">(+{parseFloat(booster).toFixed(0)}% )</span>}</p>:<></>}
                                                </div>
                                            )
                                        })}
                                    </div> :
                                        <button className="cards_button" disabled={loading}
                                            onClick={() => claimTokens([asset.asset_id])}>
                                        </button>}
                            </div>)
                    })}

                    {slots.map((asset, index) => {
                        var firstCard;
                        console.log(currentAssets.length);
                        if ((index == 0) && (currentAssets.length == 0)) { firstCard = 'cards_buildings firstCard'; } else { firstCard = 'cards_buildings'; }
                        return (
                            <div className={firstCard} key={`slots-asset-${index}`} >
                                <div className='addimg' onClick={() => popUp(true)}></div>
                            </div>)
                    })
                    }
                </>
            )
        }

        async function claimTokens(assetIDs) {
            await sendTransaction([{
                account: contract,
                name: 'claimmch',
                authorization: [{
                    actor: AccountStore.accountAddress,
                    permission: AccountStore.getUserData()[0] === "anchor" ? AccountStore.getUserData()[3] : "active",
                }], data: {
                    asset_ids: assetIDs
                },
            }], AccountStore.getUserData()[0], "Tokens claimed.").then(() => {
                setTimeout(() => {
                    loginHandler.getUserBalancetkn();
                    loginHandler.getUserBalancewax();
                    getAssets();
                }, 2000)
            }
            )
        }

        async function stakeAssets(assetIDs) {
            await sendTransaction([{
                account: contract,
                name: 'regmch',
                authorization: [{
                    actor: AccountStore.accountAddress,
                    permission: AccountStore.getUserData()[0] === "anchor" ? AccountStore.getUserData()[3] : "active",
                }],
                data: {
                    player: AccountStore.accountAddress,
                    asset_ids: assetIDs
                },
            }], AccountStore.getUserData()[0], "Assets staked.")
            setTimeout(() => {
                getAssets();
            }, 1000)
        }

        async function unStakeAssets(assetIDs) {
            await sendTransaction([{
                account: contract,
                name: 'deregmch',
                authorization: [{
                    actor: AccountStore.accountAddress,
                    permission: AccountStore.getUserData()[0] === "anchor" ? AccountStore.getUserData()[3] : "active",
                }],
                data: {
                    player: AccountStore.accountAddress,
                    asset_ids: assetIDs
                },
            }], AccountStore.getUserData()[0], "Assets unstaked.")
            setTimeout(() => {
                getAssets();
            }, 1000)
        }

        useEffect(() => {
            if (AccountStore.accountAddress) {
                loginHandler.getUserBalancetkn();
                loginHandler.getUserBalancewax();
                getAssets();
            }
        }, [AccountStore.accountAddress])

        const handleutilityType = (event) => {
            SetutilityType(event.target.value);
          };
        
          const handlefusionType = (event) => {
            SetfusionType(event.target.value);
          };

        function renderz() {
            const indexOfLastAsset = currentPage * assetsPerPage;
            const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
            const currentAssets = unstakedAssets.slice(indexOfFirstAsset, indexOfLastAsset);
            const currentAssetz = currentAssets.filter(item => {
                console.log(item);
                if (item.fusion===(fusionType!=="All"?fusionType:item.fusion) && item.utility===(utilityType!=="All"?utilityType:item.utility)) {
                    return item;
                }
            })

            return (
                <div id="divCards" className="select_card_popup">
                    <button className="staking-assets-filters-button staking-assets-filters-button-stake"
                        disabled={loading} onClick={() => {
                            popUp(false)
                        }}>X</button>
                    <div className="select_card_content">
                    <div className="staking-assets-filters">
                    <Dropdown
        label="Utility"
        options={[
        { label: 'All', value: 'All' },
        { label: 'Repair', value: 'Repair' },
          { label: 'Health', value: 'Health' },
          { label: 'Supply', value: 'Supply' },
          { label: 'Fuel', value: 'Fuel' },
        ]}
        value={utilityType}
        onChange={handleutilityType}
      />
       <Dropdown
        label="Fusion"
        options={[
            { label: 'All', value: 'All' },
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' }
        ]}
        value={fusionType}
        onChange={handlefusionType}
      />  
                </div>
      
       {currentAssetz.map((asset, index) => {
                            return (<div className="sCardsB" key={`unstaked-asset-${index}`}>
                                <div className="staking-asset-name-wrapper">
                                    <p className="staking-asset-name">{asset.name}</p>
                                    <span className="staking-asset-rate">{asset.asset_id}</span>
                                </div>
                                <img src={`https://dov-gw.mypinata.cloud/ipfs/${asset.img}`} alt=""
                                    className="cards-buildings img" onClick={() => stakeAssets([asset.asset_id])} />
                            </div>)
                        })}

                    </div>
                    <Pagination contentPerPage={assetsPerPage} totalContent={unstakedAssets.length}
                        paginate={(pageNum) => setCurrentPage(pageNum)} currentPage={currentPage} />
                </div>
            );
        }


        return (AccountStore.accountAddress ?
            <div className="parent">
                <Notification />
                <div id="buildings">
                        <div className="staking-assets-container">
                            {loading ? <Preloader /> : renderAssets()}
                        </div>
                    </div>
                {popped ? renderz() : <></>}
            </div> :
            <div className="wallet-warning">
                <h2 className="warning-message">Please connect your wallet first.</h2>
            </div>
        )
    }
    export default observer(StakingPage);