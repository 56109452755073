import { useTimer } from 'react-timer-hook';
import {observer} from "mobx-react";
import LoginHandler from "../../functions/login";

function MyTimer({ expiryTimestamp ,divID}) {
  const loginHandler = new LoginHandler();
  const {
    seconds,
    minutes,
    hours
  } = useTimer({ expiryTimestamp, onExpire: () => loginHandler.resetTimer(divID)} );
    return (
      <>
      <span>{hours}h</span>:<span>{minutes}m</span>:<span>{seconds}s</span>
      </>   );
  }
  

export default observer(MyTimer);

//0.6498