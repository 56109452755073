import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import App from './App'
import Page404 from "./pages/404";
import Footer from "./components/footer";
import ExchangePage from "./pages/exchange";
import CraftPage from "./pages/craft";
import ToolsPage from "./pages/tools";
import StakePage from "./pages/staking";
import TokenCounter from "./components/tcounter";
import DovPage from "./pages/divisions";
import AvatarPage from "./pages/avatar";
import DivisionsPage from "./pages/createdivisions";
import HOFPage from "./pages/hof";

import Header from "./components/header";
import "./index.sass";
import "./main.css";

export default function AppRoutes() {
    return (
        <App>
            <Router>
        <div className='wrapper'>
        <Header/>
        <div className='content-wrapper'>
        <TokenCounter/>
                    <Routes>
                        <Route path="/" element={<StakePage/>}/>
                        <Route path="/exchange" element={<ExchangePage/>}/>
                        <Route path="/avatars" element={<AvatarPage/>}/>
                        <Route path="/craft" element={<CraftPage/>}/>
                        <Route path="/tools" element={<ToolsPage/>}/>
                        <Route path="/fight" element={<DovPage/>}/>
                        <Route path="/create" element={<DivisionsPage/>}/>
                        <Route path="/hof" element={<HOFPage/>}/>
                        <Route path="*" element={<Page404/>}/>
                    </Routes>
                </div>
                </div>
            </Router>
        </App>
        );
}
