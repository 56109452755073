import './index.sass'
import "../../main.css"

import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Preloader from "../../components/preloader";
import Notification from "../../components/notification";
import {NotificationManager} from "react-notifications";
import LoginHandler from "../../functions/login";

function ExchangePage() {
    const { AccountStore } = StoreContext();
    var blc = AccountStore.getBlcData();
    const [loading, setLoading] = useState(false);
    const [depositOpen, setdepositOpen] = useState(true);
    const loginHandler = new LoginHandler();
    const [ctype, setType] = useState("DOVX");
    const [blcdd, setblcdd] = useState({
        dovx: "0.00",
        dovf: "0.00",
        dovh: "0.00",
        dovr: "0.00",
        dovs: "0.00"
    });
    var wfee = -1;
    const wax = loginHandler.wax;
    async function viewWithdraw() {
        if(wfee==-1)
        {
            loginHandler.getConfig();
            wfee=AccountStore.getConfig().wfee;
        }
         setdepositOpen(false);
    }

    async function viewDeposit() {
        setdepositOpen(true);
    }

    async function withdraw() {
    var value=0.00;
        if (ctype == "DOVX")
        value = blcdd.dovx;
    if (ctype == "DOVF")
    value = blcdd.dovf;
    if (ctype == "DOVH")
    value = blcdd.dovh;
    if (ctype == "DOVR")
    value = blcdd.dovr;
    if (ctype == "DOVS")
    value = blcdd.dovs;
    value=parseFloat(value).toFixed(4);

        await sendTransaction([{
            account: "dovutilstake",
            name: 'withdraw',
            authorization: [{
                actor: AccountStore.accountAddress,
                permission: AccountStore.getUserData()[0] === "anchor" ? AccountStore.getUserData()[3] : "active",
            }],
            data: {
                player: AccountStore.accountAddress,
                quantity: value.toString()+" "+ ctype
            },
        }],AccountStore.getUserData()[0], "Transaction successfull")
        setTimeout(() => {
            loginHandler.getUserBalancetkn();
            loginHandler.getUserBalancewax();
        }, 1000)
    }

    async function deposit() {

        var value=0.00;
        if (ctype == "DOVX")
        value = blcdd.dovx;
        if (ctype == "DOVF")
        value = blcdd.dovf;
        if (ctype == "DOVH")
        value = blcdd.dovh;
        if (ctype == "DOVR")
        value = blcdd.dovr;
        if (ctype == "DOVS")
        value = blcdd.dovs;
        value=parseFloat(value).toFixed(4);
    
            await sendTransaction([{
                account: loginHandler.tokenContract,
                name: 'transfer',
                authorization: [{
                    actor: AccountStore.accountAddress,
                    permission: AccountStore.getUserData()[0] === "anchor" ? AccountStore.getUserData()[3] : "active",
                }],
                data: {
                    from: AccountStore.accountAddress,
                    to: "dovutilstake",
                    quantity: value.toString()+" "+ ctype,
                    memo: "deposit"
                },
            }],AccountStore.getUserData()[0], "Transaction successfull")
            setTimeout(() => {
                loginHandler.getUserBalancetkn();
                loginHandler.getUserBalancewax();
            }, 1000)
        }

    async function sendTransaction(actions, wallet, successMessage) {
        setLoading(true);
        try {
            if (wallet === "wcw") {
               const wax = loginHandler.wax;

                const res = await wax.api.transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res;
            } else if (wallet === "anchor") {
                const res = await AccountStore.getUserData()[2].transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res.processed.id
            }
        } catch (e) {
            setLoading(false);
            if (e.message === "assertion failure with message: Nothing to claim") {
                NotificationManager.warning("Nothing to claim.", "Warning")
            } else {
                NotificationManager.error(e.message, "An error has occurred.");
            }
        }
    }

    const handleChange = (e, type) => {
        var gg = {
            dovx: 0.00,
            dovf: 0.00,
            dovh: 0.00,
            dovr: 0.00,
            dovs: 0.00
        };
        if (type == "DOVX")
            gg.dovx = e.target.value;
        if (type == "DOVF")
            gg.dovf = e.target.value;
        if (type == "DOVH")
            gg.dovh = e.target.value;
        if (type == "DOVR")
            gg.dovr = e.target.value;
        if (type == "DOVS")
            gg.dovs = e.target.value;
            setType(type);
        setblcdd(gg);
    }
    function render() {

        blc = AccountStore.getBlcData();
        var wbb = AccountStore.getWBlcData();
        if (!depositOpen)
            return (
                <div className='parent flex'>
        <Notification/>
                    <div id="exchange">
                        <div onClick={() => viewWithdraw()} className="toggleWithdraw"></div>
                        <div onClick={() => viewDeposit()} className="toggleDeposit"></div>
                        <div id="contentWithdraw">
                            <div className="feeContent">
                                <div className="mgfee">
                                    <div id="textFee">
                                        <h2>FEE : <span>{AccountStore.getConfig().wfee!=NaN?AccountStore.getConfig().wfee:"-"}</span></h2>
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td id="firstCell">Balance:</td>
                                            </tr>
                                            <tr>
                                                <td id="srCell">Withdrawal amount</td>
                                            </tr>
                                            <tr>
                                                <td id="trCell">Total token</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{blc.dovx}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovx}
                                                onChange={(evt) => { handleChange(evt, "DOVX") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovx - blcdd.dovx * (AccountStore.getConfig().wfee / 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{blc.dovf}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovf}
                                                onChange={(evt) => { handleChange(evt, "DOVF") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovf - blcdd.dovf * (AccountStore.getConfig().wfee / 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{blc.dovh}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovh}
                                                onChange={(evt) => { handleChange(evt, "DOVH") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovh - blcdd.dovh * (AccountStore.getConfig().wfee / 100)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{blc.dovs}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovs}
                                                onChange={(evt) => { handleChange(evt, "DOVS") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovs - blcdd.dovs * (AccountStore.getConfig().wfee / 100)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent mr0">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{blc.dovr}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovr}
                                                onChange={(evt) => { handleChange(evt, "DOVR") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovr - blcdd.dovr * (AccountStore.getConfig().wfee / 100)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div id="exchangeBtn">
                                <button className='btnWithdraw' onClick={()=>withdraw()}></button>
                            </div>
                        </div>
                    </div>
                </div>

            );
        else {
            return (
                <div className='parent flex'>
                    <div id="dexchange">
                        <div onClick={() => viewWithdraw()} className="toggleWithdraw"></div>
                        <div onClick={() => viewDeposit()} className="toggleDeposit"></div>
                        <div id="contentDeposit">
                            <div className="feeContent">
                                <div className="mgfee">
                                    <div id="textFee">
                                    </div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td id="firstCell">Balance:</td>
                                            </tr>
                                            <tr>
                                                <td id="srCell">Deposit amount</td>
                                            </tr>
                                            <tr>
                                                <td id="trCell">Total token</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{wbb.dovx}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovx}
                                                onChange={(evt) => { handleChange(evt, "DOVX") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovx}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{wbb.dovf}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovf}
                                                onChange={(evt) => { handleChange(evt, "DOVF") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovf}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{wbb.dovh}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovh}
                                                onChange={(evt) => { handleChange(evt, "DOVH") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovh}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{wbb.dovs}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovs}
                                                onChange={(evt) => { handleChange(evt, "DOVS") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovs}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tbContent mr0">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td id="firstCell">{wbb.dovr}</td>
                                        </tr>
                                        <tr>
                                            <td><input
                                                type="number"
                                                value={blcdd.dovr}
                                                onChange={(evt) => { handleChange(evt, "DOVR") }} />
                                        </td></tr>
                                        <tr>
                                            <td  id="trCell">{blcdd.dovr}</td>
                                        </tr>
                                         </tbody>
                                </table>
                            </div>
                            <div id="exchangeBtn">
                                <button className='btnDD' onClick={()=>deposit()}></button>
                            </div>
                        </div>
                    </div>

                </div>


            );
        }
    }

    return (
        loading?<Preloader/>:render()
    )
}

export default observer(ExchangePage);
