import './index.sass'
import * as waxjs from "@waxio/waxjs/dist";
import './burger-styles.sass'
import {observer} from "mobx-react";
import StoreContext from "../../store/RootStore";
import {NavLink, useLocation} from "react-router-dom";
import ChooseWalletModal from "../chooseWalletModal";
import {useEffect, useState} from "react";
import {ReactComponent as WalletUserIcon} from '../../assets/icons/wallet-user-icon.svg';
import { slide as Menu } from 'react-burger-menu'
import LoginHandler from "../../functions/login";
import {NotificationManager} from "react-notifications";
import { Int } from 'anchor-link';

function Header() {
    const {AccountStore} = StoreContext();
    const loginHandler = new LoginHandler();


    useEffect(() => {
        if(AccountStore.accountAddress)
            {
                 loginHandler.getUserBalancetkn();
                 loginHandler.getUserBalancewax();
    }
    if(AccountStore.avatarData.length==0)
    {
        loginHandler.getAvConfig();
    }
    }, [AccountStore.accountAddress])

    return (
<div className="head">
    <div className="token">
        <div id="dov-x"><p>{parseFloat(AccountStore.getBlcData().dovx).toFixed(2)}</p></div>
        <div id="dov-f"><p>{parseFloat(AccountStore.getBlcData().dovf).toFixed(2)}</p></div>
        <div id="dov-h"><p>{parseFloat(AccountStore.getBlcData().dovh).toFixed(2)}</p></div>
        <div id="dov-s"><p>{parseFloat(AccountStore.getBlcData().dovs).toFixed(2)}</p></div>
        <div id="dov-r"><p>{parseFloat(AccountStore.getBlcData().dovr).toFixed(2)}</p></div>
    </div>
</div>
    )
}

export default observer(Header);

//0.6498