import './index.sass'
import "../../main.css"

import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import Preloader from "../../components/preloader";
import Notification from "../../components/notification";
import {NotificationManager} from "react-notifications";
import LoginHandler from "../../functions/login";
 function CraftPage() {
    const {AccountStore} = StoreContext();
    //var blc=AccountStore.getBlcData();
    const [depositOpen, setdepositOpen] = useState(true);
    const [Crafts, setActiveCrafts] = useState([]);
    const [loading, setLoading] = useState(true);
    const loginHandler = new LoginHandler();

    const wax = loginHandler.wax;

    async function getCraftData() {
    setLoading(true);
    var rates=[];
        const body = await wax.rpc.get_table_rows({
            json: true,
            code: "dovutilstake",
            scope: "dovutilstake",
            table: "cdata",
            limit: 10,
        });
        const data = body.rows;
        const promises=await sort(data);
        const responses = await Promise.all(promises);
        console.log(responses);
        setActiveCrafts(responses);
        setLoading(false);
    }
    async function sort(dd)
    {
        const promisesToAwait = [];
        for (let i = 0; i < dd.length; i++) {
          promisesToAwait.push(GetTemplate(dd[i]));
        }
      return promisesToAwait;
    }
    async function GetTemplate (x){
        var final={};
            var path = "/atomicassets/v1/templates/" + "dawnfvictory" + "/" +x.template_id;
            const response = await fetch(loginHandler.aa + path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                method: "POST",
            });
            const body = await response.json();
            final=
                {
                    template_id : x.template_id,
                    type: x.type,
                    costs: x.costs,
                    schema:x.schema,
                    available:x.available,
                    img: body.data.immutable_data.img,
                    name: body.data.immutable_data.name
                };
                return final;
        }
        
    
    useEffect(() => {
        if (AccountStore.accountAddress) {
            getCraftData();
        }
    }, [AccountStore.accountAddress])

    async function buy(template_id) {
            await sendTransaction([{
                account: "dovutilstake",
                name: 'startcraft',
                authorization: [{
                    actor: AccountStore.accountAddress,
                    permission: AccountStore.getUserData()[0] === "anchor" ? AccountStore.getUserData()[3] : "active",
                }],
                data: {
                    player: AccountStore.accountAddress,
                    craft_id: template_id
                },
            }],AccountStore.getUserData()[0], "Transaction successfull")
            setTimeout(() => {
                getCraftData();
                loginHandler.getUserBalancetkn();
                loginHandler.getUserBalancewax();
            }, 1000)
        }

    async function sendTransaction(actions, wallet, successMessage) {
        setLoading(true);
        try {
            if (wallet === "wcw") {
                const wax = new waxjs.WaxJS("https://dovx-wax-rpc.global.binfra.one", AccountStore.accountAddress, AccountStore.getUserData()[2], false)
                const res = await wax.api.transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res;
            } else if (wallet === "anchor") {
                const res = await AccountStore.getUserData()[2].transact({
                    actions: actions
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30
                })
                setLoading(false);
                NotificationManager.success(successMessage)
                return res.processed.id
            }
        } catch (e) {
            setLoading(false);
            if (e.message === "assertion failure with message: Nothing to claim") {
                NotificationManager.warning("Nothing to claim.", "Warning")
            } else {
                NotificationManager.error(e.message, "An error has occurred.");
            }
        }
    }

    function render()
      {
        const currentAssets = Crafts.slice(0, 6);
          return(
            <>
            {currentAssets.map((asset, index) => {
                console.log(asset);
                return (
                    <div className="cards_buildings startRedCounter" key={`staked-asset-${index}`}>
                        <img src={`https://dov-gw.mypinata.cloud/ipfs/${asset.img}`} alt=""/>
                        <div class="textRedCounter">
                            <h2>{asset.template_id}</h2>
                            <h3>Remains</h3>
                            <p>{asset.available}</p>
                            {asset.available>0?<button className="craftBtn" onClick={() => buy(asset.template_id)} > </button>:<></>}
                        </div>
                        <div className="cards_button dovxRedZone">
                            {asset.costs.map((cost, index) => {
                                return (
                                    <div className="dovxText">
                                        <h3>{cost.split(" ")[1]}</h3>
                                        <p className="whiteColor">{cost.split(" ")[0]}</p>
                                    </div>
                                )})}
                        </div>
                    </div>
      )})
    }
    </>)
    }

    return (
        <div className="parent">
            <Notification/>
                {loading ? <Preloader/> :<div id="buildings">
                    <div className="content-wrapper">
                        <div className="staking-assets-container">
                            {loading ? <Preloader/> : render()}
                        </div>
                    </div>
                </div>
                }
        </div>
    )
}

export default observer(CraftPage);
